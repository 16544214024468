import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Container, Stack } from "@bookingcom/bui-react";
import { Link as RouterLink } from "react-router";

const Header = ({
  children,
  isTransparent = false,
  logo,
  logoLink = "/",
  title,
  hideTitleOnMobile = true,
  className
}) => (
  <div
    className={classnames("header-v2", className, {
      "header-v2--transparent": isTransparent
    })}
    data-testid="header-v2">
    <Container
      className="header-v2__container flex"
      mixin={{
        paddingBlockStart: 4,
        paddingBlockEnd: 4
      }}>
      {logo && (
        <RouterLink to={logoLink} className="header-v2__link">
          <Stack direction="row" alignItems="center">
            {logo}
            {title && (
              <span
                className={classnames("header-v2__title", {
                  "--booking-mobile-down-hidden": hideTitleOnMobile
                })}>
                {title}
              </span>
            )}
          </Stack>
        </RouterLink>
      )}

      {children && <div className="flex flex-col--1 header-v2__links">{children}</div>}
    </Container>
  </div>
);

Header.propTypes = {
  children: PropTypes.any,
  isTransparent: PropTypes.bool,
  logo: PropTypes.any,
  logoLink: PropTypes.string,
  title: PropTypes.string,
  hideTitleOnMobile: PropTypes.bool,
  className: PropTypes.string
};

export default Header;
