import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router";

import PATHS from "../consts/paths";
import { SessionContext } from "../context";

const NotAuth = ({ children }: React.PropsWithChildren) => {
  const { isLoggedIn } = useContext(SessionContext);
  const location = useLocation();
  const path = location.state?.referrer?.pathname || PATHS.BOOKINGS_RIDES;
  const search = location.state?.referrer?.search || "";
  return isLoggedIn ? <Navigate to={{ pathname: path, search }} replace /> : children;
};

export default NotAuth;
