import React, { useContext } from "react";
import { PATHS } from "../consts";
import { SessionContext } from "../context";
import { useLocation, Navigate } from "react-router";

const RequireAuth = ({ children }: React.PropsWithChildren) => {
  const { isLoggedIn } = useContext(SessionContext);
  const location = useLocation();
  return isLoggedIn ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: PATHS.LOGIN
      }}
      state={{ referrer: location }}
    />
  );
};
export default RequireAuth;
