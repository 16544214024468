import React, { Suspense, lazy, useEffect } from "react";
import { useLocation, Route, Routes } from "react-router";

import { Shell } from "../layouts";
import RequireAuth from "../containers/RequireAuth";
import NotAuth from "../containers/NotAuth";
import { recordPageView } from "../aws/rum";
import { FEATURE_TOGGLE, PATHS } from "../consts";
import NoMatch from "./NoMatch/NoMatch";
import useGAEvent from "../hooks/useGAEvent";
import useFeatureToggle from "../hooks/useFeatureToggle";

const Login = lazy(() => import("./Login/Login"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const FirstTimeLogin = lazy(() => import("./FirstTimeLogin"));
const Bookings = lazy(() => import("./Bookings/Bookings"));
const ManageDrivers = lazy(() => import("./Drivers/ManageDrivers"));
const Policy = lazy(() => import("./Policy"));
const ErrorPage = lazy(() => import("./Error/Error"));
const Reports = lazy(() => import("./Reports/Reports"));
const Rates = lazy(() => import("./Rates/Rates"));
const Location = lazy(() => import("./Locations/Location"));
const StopSales = lazy(() => import("./StopSells/StopSaleRouter"));
const Campaigns = lazy(() => import("./Campaigns/CampaignsRouter"));
const Genius = lazy(() => import("./Genius/GeniusRouter"));
const LandingPage = lazy(() => import("./LandingPage"));
const LeadsThankYou = lazy(() => import("./Leads/LeadsThankYou"));
const LeadsFormPage = lazy(() => import("./Leads/LeadsFormPage"));

const removePathIds = (path: string) => {
  return path
    .split("/")
    .map((part, index) => {
      if (index === 0 || isNaN(Number(part))) return part;
      return ":id";
    })
    .join("/");
};

const RootRouter = () => {
  const location = useLocation();
  const { sendEvent } = useGAEvent();

  const isGeniusEnabled = useFeatureToggle(FEATURE_TOGGLE.GENIUS);

  useEffect(() => {
    sendEvent("page_view");
    const routePath = removePathIds(location.pathname);
    recordPageView(routePath);
  }, [location, sendEvent]);

  return (
    <Shell>
      <Routes>
        <Route
          path={PATHS.LOGIN}
          element={
            <Suspense fallback={null}>
              <NotAuth>
                <Login />
              </NotAuth>
            </Suspense>
          }
        />
        <Route
          path={PATHS.RESET_PASSWORD}
          element={
            <Suspense fallback={null}>
              <NotAuth>
                <ResetPassword />
              </NotAuth>
            </Suspense>
          }
        />
        <Route
          path={PATHS.FIRST_TIME_LOGIN}
          element={
            <Suspense fallback={null}>
              <NotAuth>
                <FirstTimeLogin />
              </NotAuth>
            </Suspense>
          }
        />
        <Route path={PATHS.BOOKINGS}>
          <Route
            index
            path="*"
            element={
              <Suspense fallback={null}>
                <RequireAuth>
                  <Bookings />
                </RequireAuth>
              </Suspense>
            }
          />
        </Route>

        <Route
          path={PATHS.DRIVERS}
          element={
            <Suspense fallback={null}>
              <RequireAuth>
                <ManageDrivers />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route path={PATHS.AVAILABILITY_DASHBOARD}>
          <Route
            path="*"
            index
            element={
              <Suspense fallback={null}>
                <RequireAuth>
                  <StopSales />
                </RequireAuth>
              </Suspense>
            }
          />
        </Route>
        <Route path={PATHS.CAMPAIGNS}>
          <Route
            path="*"
            index
            element={
              <Suspense fallback={null}>
                <RequireAuth>
                  <Campaigns />
                </RequireAuth>
              </Suspense>
            }
          />
        </Route>
        <Route path={PATHS.GENIUS}>
          <Route
            path="*"
            index
            element={
              <Suspense fallback={null}>
                <RequireAuth>{isGeniusEnabled ? <Genius /> : <NoMatch />}</RequireAuth>
              </Suspense>
            }
          />
        </Route>
        <Route path={PATHS.REPORTS}>
          <Route
            path="*"
            index
            element={
              <Suspense fallback={null}>
                <RequireAuth>
                  <Reports />
                </RequireAuth>
              </Suspense>
            }
          />
        </Route>
        <Route path={PATHS.POLICY}>
          <Route
            path="*"
            index
            element={
              <Suspense fallback={null}>
                <RequireAuth>
                  <Policy />
                </RequireAuth>
              </Suspense>
            }
          />
        </Route>

        <Route path={PATHS.RATES}>
          <Route
            path={`:supplierLocationId`}
            element={
              <Suspense fallback={null}>
                <RequireAuth>
                  <Location />
                </RequireAuth>
              </Suspense>
            }
          />
          <Route
            path="*"
            index
            element={
              <Suspense fallback={null}>
                <RequireAuth>
                  <Rates />
                </RequireAuth>
              </Suspense>
            }
          />
        </Route>
        <Route
          path={PATHS.LANDING_PAGE_THANK_YOU}
          element={
            <Suspense fallback={null}>
              <LeadsThankYou />
            </Suspense>
          }
        />
        <Route
          path={PATHS.LANDING_PAGE}
          element={
            <Suspense fallback={null}>
              <LandingPage />
            </Suspense>
          }
        />
        <Route
          path={PATHS.LEADS_FORM_PAGE}
          element={
            <Suspense fallback={null}>
              <LeadsFormPage />
            </Suspense>
          }
        />
        <Route
          path={PATHS.ERROR}
          element={
            <Suspense fallback={null}>
              <ErrorPage />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <RequireAuth>
              <NoMatch />
            </RequireAuth>
          }
        />
      </Routes>
    </Shell>
  );
};

export default RootRouter;
