import { useLocation } from "react-router";

import { browserLocale } from "../utils/locale";

const removePathIds = path => {
  return path
    .split("/")
    .map((part, index) => {
      if (index === 0 || !part || isNaN(Number(part))) return part;
      return ":id";
    })
    .join("/");
};

const useGAEvent = () => {
  const location = useLocation();

  const sendEvent = (eventName, additional) => {
    if (!window?.dataLayer) {
      window.dataLayer = [];
    }

    const pageCategory = removePathIds(location.pathname);
    const siteLanguage = browserLocale();

    window.dataLayer.push({
      event: eventName,
      page_category: pageCategory,
      timestamp: Date.now(),
      site_language: siteLanguage,
      ...additional
    });
  };

  return { sendEvent };
};

export default useGAEvent;
